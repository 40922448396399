<template>
  <div
    class="advanced-explosives-site-plan px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Advanced Explosives Site Plan Training"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <a
          class="heading-link mb-4"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20Siting%20Course%20Flyer.pdf?alt=media&token=https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20Siting%20Course%20Flyer.pdf?alt=media&token=49c27c35-ab43-4f74-82cc-a63485dc1d76"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              Advanced Explosives Site Plan Training Flyer
            </h2>
          </div></a
        >
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course uses lecture, discussion, and workshops to provide an
            in-depth knowledge of explosives site plans (ESPs). Students will be
            immersed in quantity distance (QD) tables, criteria, and
            calculations used in DOD 4145.26M. Students will learn to perform
            in-depth evaluations of ESP scenarios based on real-world examples
            and prepare required site plan documentation. The course will review
            mitigation strategies used to reduce risk and address regulatory
            compliance issues. Students will also learn how to apply maximum
            credible event (MCE) analysis and integrate other analyses into ESP
            documentation to promote understanding for a smooth approval
            process.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Review of basic explosives safety siting principles</li>
            <li>In-process classification</li>
            <li>QD application workshops</li>
            <li>Facility siting principles</li>
            <li>DoD 4145.26-M</li>
            <li>QD calculation theory</li>
            <li>Site plan templates and examples</li>
            <li>Shipping configuration</li>
            <li>ETUG and GHS overview</li>
            <li>Explosion effects and consequences</li>
            <li>Barricade benefits</li>
            <li>Primary explosive handling</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Advanced Explosives Site Plan Course",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course provides an in-depth knowledge of explosives site plans (ESPs). Students learn about quantity distance (QD) tables, criteria, and calculations used in DOD 4145.26M."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.advanced-explosives-site-plan {
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
